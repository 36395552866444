import { SecurityOutlined } from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import ListAltIcon from "@mui/icons-material/ListAlt";

import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { MenuItem } from "../types/Menu";

export const MENUITEMS: MenuItem[] = [
  {
    label: "Inicio",
    icon: <HomeIcon />,
    to: "home",
    permissions: ["is_superuser", "HOME:READ"],
  },
  {
    label: "Pedidos",
    icon: <ListAltIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Gestión",
        to: "settingmanagement",
        permissions: ["is_superuser", "MANAGEMENT:READ"],
      },
    ],
    permissions: ["is_superuser", "ORDERS:READ"],
  },
  {
    label: "Reportes",
    icon: <TextSnippetIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Pedidos",
        to: "settingReportOrders",
        permissions: ["is_superuser", "REPORTORDERS:READ"],
      },
      {
        label: "Mensajeros",
        to: "settingReportDrivers",
        permissions: ["is_superuser", "	REPORTDRIVERS:READ"],
      },
      {
        label: "Entregas",
        to: "settingReportZones",
        permissions: ["is_superuser", "REPORTZONES:READ"],
      },
    ],
    permissions: ["is_superuser", "REPORT:READ"],
  },
  {
    label: "Mantenimiento",
    icon: <AvTimerIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Inventario",
        to: "inventory",
        permissions: ["is_superuser", "INVENTORY:READ"],
      },
      {
        label: "Rutas",
        to: "routes",
        permissions: ["is_superuser", "ROUTES:READ"],
      },
      {
        label: "Bancos",
        to: "banks",
        permissions: ["is_superuser", "BANKS:READ"],
      },
    ],

    permissions: ["is_superuser", "MAINTENANCEMENU:READ"],
  },
  {
    label: "Mensajeros",
    icon: <DriveEtaIcon />,
    to: "drivers",
    permissions: ["is_superuser", "DRIVERS:READ"],
  },
  {
    label: "Configuración",
    icon: (
      <SettingsIcon
        sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }}
      />
    ),
    to: null,
    expand: true,
    children: [
      {
        label: "Usuarios",
        to: "settinguser",
        permissions: ["is_superuser", "USER:READ"],
      },
      {
        label: "Perfiles",
        to: "settingroles",
        permissions: ["is_superuser", "ROLE:READ"],
      },
      {
        label: "Permisos Web",
        to: "settingauthority",
        permissions: ["is_superuser", "AUTHORITY:READ"],
      },
      {
        label: "Permisos Móvil",
        to: "settingAuthorityMobile",
        permissions: ["is_superuser", "AUTHORITYMOBILE:READ"],
      },
      {
        label: "Empresa",
        to: "company",
        permissions: ["is_superuser", "COMPANY:READ"],
      },
    ],
    permissions: ["is_superuser", "SETTINGSMENU:READ"],
  },
  {
    label: "Cerrar sesión",
    icon: <ExitToAppIcon />,
    to: null,

    permissions: ["is_superuser"],
  },
];

export const topBarHeight = 64;
export const sideNavWidth = 260;
