import { Grid, Typography, Divider, useTheme } from "@mui/material";

interface dataType {
  title: string;
}

const SubTilteDialog = ({ title }: dataType) => {
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Typography variant="h6" sx={{ mt: 2, color: theme.palette.info.dark }}>
        {title}
      </Typography>
      <Divider sx={{ mb: 2 }} />
    </Grid>
  );
};

export default SubTilteDialog;
