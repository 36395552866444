import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { serialize } from 'object-to-formdata';

// Opciones para la serialización
const options = {
  indices: true,
  dotsForObjectNotation: true,
};

export const getRequestOrders = async (page: number, startDate: string, endDate: string, company?: string,) => {
    try {
        const response = await privateFetch.get(URL.REQUEST_REPORT_ORDERS(page, UrlBase.registration_x_page, startDate, endDate, company));
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getRequestOrdersDashboard = async (page: number, startDate: string, endDate: string, company?: string,) => {
    try {
        const response = await privateFetch.get(URL.REQUEST_REPORT_ORDER(page, 1000, startDate, endDate, company));
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getRequestOrdersExcel = async (page: number, startDate: string, endDate: string, company?: string,) => {
    try {
        window.open(UrlBase.base_url + UrlBase.api_context + URL.REQUEST_REPORT_ORDERS_EXCEL(page, UrlBase.registration_x_page, startDate, endDate, company), 'blank');
    } catch (error: any) {
        throw new Error("Error al obtener reporte de pedidos");
    }

};

export const getRequestDrivers = async (page: number, startDate: string, endDate: string, company?: string,) => {
    try {
        const response = await privateFetch.get(URL.REQUEST_REPORT_DRIVERS(page, UrlBase.registration_x_page, startDate, endDate, company));
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getRequestDriversExcel = async (page: number, startDate: string, endDate: string, company?: string,) => {
    try {
        window.open(UrlBase.base_url + UrlBase.api_context + URL.REQUEST_REPORT_DRIVERS_EXCEL(page, UrlBase.registration_x_page, startDate, endDate, company), 'blank');
    } catch (error: any) {
        throw new Error("Error al obtener reporte de pedidos");
    }

};

export const uploadExcelFile = async (data: any) => {
    try {
      // Crear FormData con el archivo de Excel
      const formData = new FormData();
  
      if (data.excelFile instanceof File) {
        formData.append('excelFile', data.excelFile);
      }
  
      // Realiza la petición para subir el archivo
      const response = await privateFetch.post(
        URL.REQUEST_UPLOAD_EXCEL(),
        formData,
        {
          headers: {
            ...CONSTANT.HEADER_TYPE_DOCUMENTS,
          },
          // Configura la respuesta para que sea un blob (archivo)
          responseType: 'blob',
        }
      );
  
      // Crear un objeto URL para el archivo descargado
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  
      // Crear un enlace para simular la descarga
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Resultado_Ordenes.xlsx'); 
      document.body.appendChild(link);
      link.click();
  
      // Limpiar el objeto URL
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
      
    } catch (error: any) {
      console.error(error);
      throw new Error('Error al procesar el archivo');
    }
  };
