import { useState, useEffect, createContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { SVCancelSubscriptionRequest, SVSessionValidateRequest } from "../services/Security";
import { requestTypes } from "../types/Context";
import { setAuthData, setItem, getItem } from "../utils/LocalStorageManager";
import {
  getRequestHistoryGroupByPackStatus,
  getRequestHistoryByPackStatus,
} from "../services/order/ClientOrder";
import { formatDateReverse } from "../utils/UtilsFunctions";
import { OrderType, routeType } from "../pages/tabsManagement/orderTypes";
import {
  initialStateContent,
  initialStateRoute,
} from "../pages/tabsManagement/initialStateOrderByStatus";
import { getRequestALLDrivers } from "../services/user/User";
import { clientOrderByStatusType } from "../types/Context";
import { getRequestRouteAll } from "../services/routes/RoutesService";

const initialState = {
  isLoading: false,
  setLoading: () => {},
  errorMsg: "",
  setErrorMsg: () => {},
  successMsg: "",
  setSuccessMsg: () => {},
  loginProvider: () => {},
  handleFetchHistoryGroupByPackStatus: () => {},
  onSubmitCancelSubscription: () => {},
  handleHistoryByPackStatus: () => {},
  historyGroupByPackStatus: {
    assigned: initialStateContent,
    onTheWay: initialStateContent,
    delivered: initialStateContent,
    rejected: initialStateContent,
    pending: initialStateContent,
  },
  allDrivers: initialStateContent,
  handleGetDrivers: () => {},
  routeAll: initialStateRoute,
  handleFetchRouteAll: () => {},
  countTabs: {
    pending: 0,
    assigned: 0,
    onTheWay: 0,
    delivered: 0,
    rejected: 0,
  },
  setCountTabs: () => {},
};

const AppContext = createContext<requestTypes>(initialState);

const AppProvider = ({ children, authData }: any) => {
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useState(authData);
  const [authenticate, setAuthenticate] = useState(authData !== null);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [historyGroupByPackStatus, setHistoryGroupByPackStatus] = useState(
    initialState.historyGroupByPackStatus
  );
  const [allDrivers, setAllDrivers] = useState<clientOrderByStatusType>(
    initialState.allDrivers
  );
  const [routeAll, setRouteAll] = useState<routeType | null>(null);
  const [countTabs, setCountTabs] = useState(initialState.countTabs);

  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });

  const loginProvider = async (loginData: any) => {
    console.log(loginData);
    try {
      const sessionCode = loginData.sessionCode;
      const email = loginData.user.email;
      const id = loginData.id;
      const company = loginData.user.company;

      if (sessionCode && email) {
        const loginDataValidate = await SVSessionValidateRequest(
          email,
          sessionCode,
          id
        );
        let formatData = {
          ...loginDataValidate,
          company: company,
        };
        //save login data in storage
        const authData = await setAuthData("authASAPMsj", formatData);
        if (!authData) {
          setAuthenticate(false);
          return;
        }
        const decodeToken: any = await jwtDecode(
          authData?.tokenInfo?.access_token
        );
        const expiresAt = await decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        if (valid) {
          setAuthenticate(valid);
          setAuthInfo(authData);
          if (authData?.userRole?.id === 2) {
            navigate("/dashboard/settingmanagement");
          } else {
            navigate("/dashboard/home");
          }
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async (e: any) => {
    await setAuthData("authASAPMsj", null);
    setAuthenticate(false);
    navigate("/", { replace: true });
  };

  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  const resetSuccessMsg = () => {
    setSuccessMsg("");
  };

  const currentDateMinusOneMonth = new Date(new Date()).setMonth(
    new Date(new Date()).getMonth() - 1
  );

  const handleFetchHistoryGroupByPackStatus = async (companyId: number) => {
    try {
      setLoading(true);
      const data = await getRequestHistoryGroupByPackStatus(
        formatDateReverse(new Date(currentDateMinusOneMonth)),
        formatDateReverse(new Date()),
        0,
        companyId
      );

      if (data) {
        setHistoryGroupByPackStatus(data);
        setCountTabs({
          pending: data.pending.totalElements,
          assigned: data.assigned.totalElements,
          onTheWay: data.onTheWay.totalElements,
          delivered: data.delivered.totalElements,
          rejected: data.rejected.totalElements,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleHistoryByPackStatus = async (
    packageStatus: number,
    page: number,
    filter: string,
    companyId: number
  ) => {
    try {
      setLoading(true);
      const dataPacks: OrderType = await getRequestHistoryByPackStatus(
        packageStatus,
        formatDateReverse(new Date(currentDateMinusOneMonth)),
        formatDateReverse(new Date()),
        page,
        filter,
        companyId
      );
      setLoading(false);
      return dataPacks;
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const handleGetDrivers = async () => {
    try {
      setLoading(true);
      const data: any = await getRequestALLDrivers(0, "");
      if (data) {
        setAllDrivers(data);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const handleFetchRouteAll = async () => {
    try {
      setLoading(true);
      const routes = await getRequestRouteAll();
      setRouteAll(routes);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const onSubmitCancelSubscription = async (data: any) => {
    try {
        setLoading && setLoading(true);
        //Call service user
        const resultData: any = await SVCancelSubscriptionRequest(data);
        setSuccessMsg && setSuccessMsg(resultData.info);
        setLoading && setLoading(false);
        
    } catch (error: any) {
        setLoading && setLoading(false);
        setErrorMsg &&
            setErrorMsg(
                error.message
                    ? error.message
                    : "Error al cancelar la suscripción"
            );
    }
};


  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
      historyGroupByPackStatus,
      handleFetchHistoryGroupByPackStatus,
      onSubmitCancelSubscription,
      handleHistoryByPackStatus,
      allDrivers,
      handleGetDrivers,
      routeAll,
      handleFetchRouteAll,
      countTabs,
      setCountTabs,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
      historyGroupByPackStatus,
      allDrivers,
      routeAll,
      countTabs,
    ]
  );

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
