import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  TextField,
  Autocomplete,
  Grid,
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useApp } from "../../hooks/useApp";
import { deliveryCourier } from "../../pages/tabsManagement/orderTypes";

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

interface addOrUpdateDriver {
  deliveryCourier: deliveryCourier;
}

const AssignDriverModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { allDrivers } = useApp();

  const validation = Yup.object().shape({
    deliveryCourier: Yup.object()
      .shape({
        id: Yup.string().required("Campo es requerido"),
        firstName: Yup.string().required("Campo es requerido"),
      })
      .typeError("Campo es requerido")
      .required("Campo es requerido"),
  });

  const defaultValues = {
    ...data,
    deliveryCourier: data.deliveryCourier,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<addOrUpdateDriver>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const [selectedDriver, setSelectedDriver] = useState<number | null>(null);

  return (
    <>
      <DialogTitle>Asignar conductor</DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={3} maxWidth="xl" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="deliveryCourier"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(e, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={
                    allDrivers !== null
                      ? allDrivers.content
                      : [{ id: 0, name: "" }]
                  }
                  getOptionLabel={(option: deliveryCourier) =>
                    option.firstName + " " + option.lastName || ""
                  }
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  //@ts-ignore
                  value={value}
                  sx={{ pb: 0 }}
                  renderInput={(params: any) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Mensajero"
                        variant="standard"
                        error={!!errors.deliveryCourier}
                        helperText={
                          errors.deliveryCourier &&
                          errors.deliveryCourier.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {"Aceptar"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {"Cancelar"}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AssignDriverModal;
