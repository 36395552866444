import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FancyDialogActions from "./FancyDialogActions";

interface ZoneType {
  id: number;
  name: string;
}

interface ZonePrice {
  zone: ZoneType;
  clientAmount?: string;
  deliveryAmountCourier?: string;
  order?: string;
}

interface FormValues {
  companyId: number;
  zonePrice: ZonePrice[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  company: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  zones: ZoneType[];
}

const AddOrUpdatePriceZone = ({
  actionButton,
  data,
  company,
  onSubmit,
  cancelModal,
  modalType,
  zones,
}: DialogProps) => {
  const validationSchema = Yup.object().shape({
    companyId: Yup.number().required("Campo es requerido"),
    zonePrice: Yup.array().of(
      Yup.object().shape({
        zone: Yup.object().shape({
          id: Yup.number().required(),
          name: Yup.string().required(),
        }),
        clientAmount: Yup.string(),
        deliveryAmountCourier: Yup.string(),
        order: Yup.string(),
      })
    ),
  });

  // Combine all available zones with the zones that have prices assigned
  const combinedZones = zones.map(zone => {
    const foundZonePrice = data?.find((zp: any) => zp.zone.id === zone.id);
    return {
      zone,
      clientAmount: foundZonePrice ? String(foundZonePrice.clientAmount) : "",
      deliveryAmountCourier: foundZonePrice ? String(foundZonePrice.deliveryCourierAmount) : "",
      order: foundZonePrice ? String(foundZonePrice.thirdPartyShippingAmount) : ""
    };
  });

  const defaultValues = {
    companyId: company.id ?? 0,
    zonePrice: combinedZones,
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { fields } = useFieldArray({
    control,
    name: "zonePrice",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar precios por zona" : "Actualizar precios por zona"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {fields.map((field, index) => (
              <Grid container item spacing={2} key={field.id}>
                <Grid item xs={12}>
                  <Typography variant="h6">{field.zone.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name={`zonePrice.${index}.clientAmount`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Monto Cliente"
                        type="text"
                        variant="standard"
                        {...field}
                        error={Boolean(errors.zonePrice?.[index]?.clientAmount)}
                        helperText={
                          errors.zonePrice?.[index]?.clientAmount?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name={`zonePrice.${index}.deliveryAmountCourier`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Monto Mensajero"
                        type="text"
                        variant="standard"
                        {...field}
                        error={Boolean(errors.zonePrice?.[index]?.deliveryAmountCourier)}
                        helperText={
                          errors.zonePrice?.[index]?.deliveryAmountCourier?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name={`zonePrice.${index}.order`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Monto Encomienda"
                        type="text"
                        variant="standard"
                        {...field}
                        error={Boolean(errors.zonePrice?.[index]?.order)}
                        helperText={
                          errors.zonePrice?.[index]?.order?.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdatePriceZone;
