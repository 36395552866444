import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";

interface AddOrUpdateInventoryTypes {
  id?: number;
  product: string;
  serial: string;
  status: boolean;
  comments?: string;
}

const AddOrUpdateInventory = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const validation = Yup.object().shape({
    product: Yup.string().required("Campo es requerido"),
    serial: Yup.string().required("Campo es requerido"),
    status: Yup.boolean().required("Campo es requerido"),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    product: data?.product ?? "",
    serial: data?.number ?? "",
    status: data?.status ?? true,
    comments: data?.comments ?? "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateInventoryTypes>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar producto" : "Actualizar producto"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"product"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Producto"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("product")}
                    error={errors.product && Boolean(errors.product)}
                    helperText={errors.product && errors.product.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"serial"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Serial"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("serial")}
                    error={errors.serial && Boolean(errors.serial)}
                    helperText={errors.serial && errors.serial.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"comments"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Comentarios"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("comments")}
                    error={errors.comments && Boolean(errors.comments)}
                    helperText={errors.comments && errors.comments.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"status"}
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Estado"
                    variant="standard"
                    {...field}
                    value={field.value ? "true" : "false"}
                    onChange={(e) => {
                      const selectedType = e.target.value === "true";
                      field.onChange(selectedType);
                    }}
                    error={errors.status && Boolean(errors.status)}
                    helperText={errors.status && errors.status.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 224,
                            width: 250,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="true">Disponible</MenuItem>
                    <MenuItem value="false">Entregado</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateInventory;
