import { Tabs, Tab, Box, IconButton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import { addOrUpdOneOrderDetail, addOrUpdOrderDetail } from "../services/order/ClientOrder";
import { saveSerialsType } from "./tabsManagement/orderTypes";
import TabCardPendingForm from "./tabsManagement/TabCardPendingForm";
import TabCardAssignedForm from "./tabsManagement/TabCardAssignedForm";
import TabCardProgressForm from "./tabsManagement/TabCardProgressForm";
import TabCardFinishedForm from "./tabsManagement/TabCardFinishedForm";
import TabCardRejectedForm from "./tabsManagement/TabCardRejectedForm";
import {
  itemOrderType,
  saveStatusOrderType,
  orderStatusAllType,
} from "./tabsManagement/orderTypes";
import {
  addOrUpdClientOrder,
  deleteClientOrder,
  addOrUpdOrderStatus,
  getRequestOrderStatusAll,
  addOrUpdOrderDocuments,
} from "../services/order/ClientOrder";
import { uploadDocuments } from "../services/blob/BlobStorage";
import { properties } from "../utils/Properties_es";
import { Refresh } from "@mui/icons-material";

const SettingManagementBiker = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    handleFetchHistoryGroupByPackStatus,
    handleGetDrivers,
    handleFetchRouteAll,
    countTabs,
  } = useApp();

  const [companyData, setCompanyData] = useState<any>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [updatePendingCount, setupdatePendingCount] = useState<number>(0);
  const [updateAssignedCount, setupdateAssignedCount] = useState<number>(0);
  const [updateOnTheWayCount, setupdateOnTheWayCount] = useState<number>(0);
  const [updateFinishCount, setupdateFinishCount] = useState<number>(0);
  const [updateRejectedCount, setupdateRejectedCount] = useState<number>(0);
  const [showRefreshMessage, setShowRefreshMessage] = useState(false);
  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);
  const [allOrderStatus, setAllOrderStatus] = useState<
    orderStatusAllType[] | null
  >(null);
  const [images, setImages] = useState<any>([]);

  console.log(allOrderStatus)

  const fetchData = async () => {
    await handleFetchHistoryGroupByPackStatus(
      authInfo?.company?.id !== 10 ? authInfo?.company?.id : 0
    );
    await handleFetchOrderStatusAll();
  };

  const handleUserActivity = () => {
    // Reinicia el temporizador si el usuario realiza alguna actividad
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }

    inactivityTimer.current = setTimeout(() => {
      setShowRefreshMessage(true);
    }, 10 * 60 * 1000); // 10 minutos
  };

  useEffect(() => {
    // Añadir listeners para detectar actividad
    //window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("mousedown", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity);

    // Establecer el temporizador inicial
    handleUserActivity();

    return () => {
      // Limpiar listeners y temporizador al desmontar el componente
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("mousedown", handleUserActivity);
      window.removeEventListener("touchstart", handleUserActivity);

      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, []);

  const handleRefreshClick = () => {
    fetchData();
    setShowRefreshMessage(false);
  };

  useEffect(() => {
    const getData = async () => {
      await handleFetchRouteAll();
      await handleGetDrivers();
    };
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dataInit = async () => {
      handleFetchHistoryGroupByPackStatus(
        authInfo?.company?.id !== 10 ? authInfo?.company?.id : 0
      );
      handleFetchOrderStatusAll();
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchOrderStatusAll = async () => {
    setLoading && setLoading(true);
    try {
      let data: any = await getRequestOrderStatusAll();
      if (data) {
        setAllOrderStatus(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleUpdateTabs = async (status: number) => {
    switch (status) {
      case 1:
        setupdatePendingCount((updatePendingCount) => updatePendingCount + 1);
        break;
      case 2:
        setupdateAssignedCount(
          (updateAssignedCount) => updateAssignedCount + 1
        );
        break;
      case 3:
        setupdateOnTheWayCount(
          (updateOnTheWayCount) => updateOnTheWayCount + 1
        );
        break;
      case 4:
        setupdateFinishCount((updateFinishCount) => updateFinishCount + 1);
        break;
      case 5:
        setupdateRejectedCount((updateRejectedCount) => updateRejectedCount + 1);
        break;
      default:
        break;
    }
  };

  const handleAdd = async (formData: any) => {
    console.log("Add: ", formData);
    handleCancelModal();

    let formatAddDatta: itemOrderType = {
      identification: formData.identification,
      company: formData.company,
      notes: formData.notes,
      route: formData.route,
      name: formData.name,
      phone: formData.phone,
      reference1: formData.reference1,
      phone1: formData.phone1,
      reference2: formData.reference2,
      phone2: formData.phone2,
      startDate: new Date(),
      dueDate: formData.dueDate,
      amount: 0,
      deliveryCourierAmount: 0,
      thirdPartyShippingAmount: 0,
      address: formData.address,
      latitude: formData.latitude,
      longitude: formData.longitude,
      sourceAddress: formData.sourceAddress,
      sourceDistrict: formData.sourceDistrict,
    };
    //Agregar valores de las series en un solo array de strings
    let mySerialsArray: any = [];
    await formData.seriesNumbers.map((item: any) =>
      mySerialsArray.push(item.value)
    );
    setLoading && setLoading(true);
    try {
      const createData: any = await addOrUpdClientOrder(formatAddDatta);
      if (!createData) {
        setErrorMsg && setErrorMsg("Error al guardar orden");
        setLoading && setLoading(false);
        return;
      }
      let dataSerialsDetail: saveSerialsType = {
        clientOrderId: createData.id,
        serials: mySerialsArray,
      };
      //Guardar los seriales de la orden
      if (mySerialsArray.length > 0 && mySerialsArray[0] !== "") {
        const saveSerials = await handleAddOrUpdOrderDetail(dataSerialsDetail);
        console.log("saveSerials: ", saveSerials);
        if (!saveSerials) {
          setErrorMsg && setErrorMsg("Error al guardar seriales de la orden");
        }
      }
      if (formData.images && formData.images.length > 0) {
        // Iterar sobre cada archivo en el FileList
        for (let i = 0; i < formData.images.length; i++) {
          const file = formData.images[i];
          if (file) {
            const addImage = await handleUploadDocument([file]);
            if (addImage) {
              let dataOrderDocument = {
                fileName: addImage.relativePath,
                url: addImage.path,
                clientOrderId: createData.id,
              };
              // Actualizar la orden con los IDs de las imágenes agregadas
              await addOrUpdOrderDocuments(dataOrderDocument);
            }
          }
        }
      }

      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      await handleUpdateTabs(1);
      setLoading && setLoading(false);
    } catch (error: any) {
      console.log(error);
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (
    status: number,
    formData: any /* formData: itemOrderType */
  ) => {
    console.log("handleUpdate: ", formData);
    handleCancelModal();
    setLoading && setLoading(true);
    let formatAddDatta: itemOrderType = {
      id: formData.id,
      identification: formData.identification,
      company: formData.company,
      notes: formData.notes,
      route: formData.route,
      name: formData.name,
      phone: formData.phone,
      reference1: formData.reference1,
      phone1: formData.phone1,
      reference2: formData.reference2,
      phone2: formData.phone2,
      startDate: new Date(),
      dueDate: formData.dueDate,
      amount: 0,
      deliveryCourierAmount: 0,
      thirdPartyShippingAmount: 0,
      address: formData.address,
      latitude: formData.latitude,
      longitude: formData.longitude,
      sourceAddress: formData.sourceAddress,
      sourceDistrict: formData.sourceDistrict,
      deliveryCode: formData.deliveryCode,
      deliveryCourier: formData.deliveryCourier,
    };
    try {
      const updateData:any = await addOrUpdClientOrder(formatAddDatta);
      if (!updateData) {
        setErrorMsg && setErrorMsg("Error al actualizar orden");
        setLoading && setLoading(false);
        return;
      }
      //Agregar valores de las series en un solo array de strings
      let mySerialsArray: any = [];
      await formData?.seriesNumbers?.map((item: any) =>
        mySerialsArray.push(item.value)
      );
      let dataSerialsDetail: saveSerialsType = {
        clientOrderId: formData.id,
        serials: mySerialsArray,
      };
      //Guardar los seriales de la orden
      if (mySerialsArray.length > 0 && mySerialsArray[0] !== "") {
        const saveSerials = await handleAddOrUpdOrderDetail(dataSerialsDetail);
        console.log("saveSerials: ", saveSerials);
        if (!saveSerials) {
          setErrorMsg && setErrorMsg("Error al guardar seriales de la orden");
        }
      }
      if (formData.images && formData.images.length > 0) {
        // Iterar sobre cada archivo en el FileList
        for (let i = 0; i < formData.images.length; i++) {
          const file = formData.images[i];
          if (file) {
            const addImage = await handleUploadDocument([file]);
            if (addImage) {
              let dataOrderDocument = {
                fileName: addImage.relativePath,
                url: addImage.path,
                clientOrderId: updateData.id,
              };
              // Actualizar la orden con los IDs de las imágenes agregadas
              await addOrUpdOrderDocuments(dataOrderDocument);
            }
          }
        }
      }
      if (formData.orderDetail && formData.orderDetail.length > 0) {
        // Iterar sobre cada archivo en el FileList
        for (let i = 0; i < formData.orderDetail.length; i++) {
          const serial = formData.orderDetail[i];
          if (serial) {
            await addOrUpdOneOrderDetail({serial});
          }
        }
      }
      //addOrUpdOneOrderDetail
      await fetchData();
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleAssignedDriver = async (status: number, data: itemOrderType) => {
    console.log("handleAssignedDriver: ", data);
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      const updateData = await addOrUpdClientOrder(data);
      if (!updateData) {
        setErrorMsg && setErrorMsg("Error al agregar mensajero");
        setLoading && setLoading(false);
        return;
      }
      if (status === 1) {
        let updateStatusAssingDriver = {
          clientOrderId: data.id,
          packageStatusId: 2,
        };
        await addOrUpdOrderStatus(updateStatusAssingDriver);
        await fetchData();
      }
      setSuccessMsg && setSuccessMsg("Mensajero asignado correctamente");
      setLoading && setLoading(false);
      await handleUpdateTabs(status);
    } catch (error: any) {
      console.log(error);
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  const handleAddOrUpdateStatusOrder = async (
    status: number,
    data: saveStatusOrderType
  ) => {
    console.log("handleAddOrUpdateStatusOrder status: ", status);
    console.log("handleAddOrUpdateStatusOrder data: ", data);
    handleCancelModal();
    setLoading && setLoading(true);
    let formData = {
      clientOrderId: data.clientOrderId,
      packageStatusId: data.packageStatusId.id,
      impediment: data.impediment,
      description: data.description,
    };
    try {
      const request = await addOrUpdOrderStatus(formData);
      if (!request) {
        setErrorMsg && setErrorMsg("Error al actualizar estado");
        setLoading && setLoading(false);
        return;
      }
      setSuccessMsg && setSuccessMsg("Estado actualizado correctamente");
      setLoading && setLoading(false);
      await fetchData();
    } catch (error: any) {
      console.log(error);
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log("handleDelete: ", id);
    try {
      let deleteData = await deleteClientOrder(id);
      if (!deleteData) {
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      await handleUpdateTabs(1);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUploadDocument = async (acceptedFiles: File[]) => {
    let responseAddImage: any = null;
    try {
      if (acceptedFiles && acceptedFiles[0]?.name) {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0], acceptedFiles[0]?.name);
        responseAddImage = await uploadDocuments(formData);
      }
      setLoading && setLoading(false);
      return responseAddImage;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleAddOrUpdOrderDetail = async (data: saveSerialsType) => {
    try {
      const save = await addOrUpdOrderDetail(data);
      if (!save) {
        setErrorMsg && setErrorMsg("Error al guardar seriales en orden");
        setLoading && setLoading(false);
        return;
      }
      return save;
    } catch (error: any) {
      console.log(error);
      setErrorMsg && setErrorMsg(error);
    }
  };

  return (
    <>
      <FancyPaper pagetitle={`${"Pedidos"} / ${"Gestión"}`}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-flexContainer": { justifyContent: "flex-end" },
              "& .MuiTab-root": {
                borderRadius: 3,
                minWidth: "100px",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <Tab
              label={`Pendientes (${countTabs.pending})`}
              sx={{
                backgroundColor: tabIndex === 0 ? "red" : "transparent",
                color: tabIndex === 0 ? "white" : "red",
                "&:hover": { color: "red" },
                "&.Mui-selected": { color: "white" },
                fontWeight: "bold",
                fontSize: "1rem",
                letterSpacing: "0.05rem",
              }}
            />
            <Tab
              label={`Asignados (${countTabs.assigned})`}
              sx={{
                backgroundColor: tabIndex === 1 ? "orange" : "transparent",
                color: tabIndex === 1 ? "white" : "orange",
                "&:hover": { color: "orange" },
                "&.Mui-selected": { color: "white" },
                fontWeight: "bold",
                fontSize: "1rem",
                letterSpacing: "0.05rem",
              }}
            />
            <Tab
              label={`En curso (${countTabs.onTheWay})`}
              sx={{
                backgroundColor: tabIndex === 2 ? "#EBBB00" : "transparent",
                color: tabIndex === 2 ? "white" : "#EBBB00",
                "&:hover": { color: "#EBBB00" },
                "&.Mui-selected": { color: "white" },
                fontWeight: "bold",
                fontSize: "1rem",
                letterSpacing: "0.05rem",
              }}
            />
            <Tab
              label={`Finalizados (${countTabs.delivered})`}
              sx={{
                backgroundColor: tabIndex === 3 ? "green" : "transparent",
                color: tabIndex === 3 ? "white" : "green",
                "&:hover": { color: "green" },
                "&.Mui-selected": { color: "white" },
                fontWeight: "bold",
                fontSize: "1rem",
                letterSpacing: "0.05rem",
              }}
            />
            <Tab
              label={`Rechazados (${countTabs.rejected})`}
              sx={{
                backgroundColor: tabIndex === 4 ? "grey" : "transparent",
                color: tabIndex === 4 ? "white" : "grey",
                "&:hover": { color: "grey" },
                "&.Mui-selected": { color: "white" },
                fontWeight: "bold",
                fontSize: "1rem",
                letterSpacing: "0.05rem",
              }}
            />
          </Tabs>
          <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
            <Box sx={{ position: "relative", marginRight: "auto" }}>
              {showRefreshMessage && (
                <Typography
                  sx={{
                    position: "absolute",
                    top: -50,
                    left: -200, // Ajusta la posición del mensaje para moverlo hacia la izquierda
                    color: "red",
                    //fontWeight: "bold",
                    fontSize: "0.875rem",
                    whiteSpace: "nowrap", // Asegura que el texto no se divida en múltiples líneas
                  }}
                >
                  Se recomienda refrescar los datos
                </Typography>
              )}
            </Box>
            <IconButton color="primary" onClick={handleRefreshClick}>
              <Refresh sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          </Box>
          {/*   <Stack direction="row" alignItems="center">
            <TextField
              placeholder="Filtro de búsqueda"
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  borderRadius: "0.2rem 0 0 0.2rem",
                },
                "& fieldset": {
                  borderRightWidth: "0",
                },
              }}
              value={preFilter}
              onChange={(e: any) => {
                setPreFilter(e.target.value);
                if (e.target.value === "") {
                  handleFetchHistoryGroupByPackStatus();
                }
              }}
              onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleApplyFilter}
              sx={{
                borderRadius: "0 0.2rem 0.2rem 0!important",
              }}
            >
              Buscar
            </Button>
          </Stack> */}
        </Box>
        <Box sx={{ mt: 10 }}>
          {tabIndex === 0 && (
            <TabCardPendingForm
              data={companyData.card}
              handleAdd={handleAdd}
              handleUpdate={handleUpdate}
              handleAssignedDriver={handleAssignedDriver}
              handleDelete={handleDelete}
              updatePendingCount={updatePendingCount}
              allOrderStatus={
                allOrderStatus?.filter(
                  (item: orderStatusAllType) => item.id > 1
                ) ?? null
              }
              handleAddOrUpdateStatusOrder={handleAddOrUpdateStatusOrder}
              images={images}
              setImages={setImages}
            />
          )}
          {tabIndex === 1 && (
            <TabCardAssignedForm
              data={companyData.card}
              handleAdd={handleAdd}
              handleUpdate={handleUpdate}
              handleAssignedDriver={handleAssignedDriver}
              updateAssignedCount={updateAssignedCount}
              allOrderStatus={
                allOrderStatus?.filter(
                  (item: orderStatusAllType) => item.id > 2
                ) ?? null
              }
              handleAddOrUpdateStatusOrder={handleAddOrUpdateStatusOrder}
            />
          )}
          {tabIndex === 2 && (
            <TabCardProgressForm
              data={companyData.card}
              handleAdd={handleAdd}
              handleUpdate={handleUpdate}
              updateOnTheWayCount={updateOnTheWayCount}
              allOrderStatus={
                allOrderStatus?.filter(
                  (item: orderStatusAllType) => item.id > 4
                ) ?? null
              }
              handleAddOrUpdateStatusOrder={handleAddOrUpdateStatusOrder}
            />
          )}
          {tabIndex === 3 && (
            <TabCardFinishedForm
              data={companyData.card}
              handleAdd={handleAdd}
              handleUpdate={handleUpdate}
              handleAssignedDriver={handleAssignedDriver}
              updateFinishCount={updateFinishCount}
              allOrderStatus={
                allOrderStatus?.filter(
                  (item: orderStatusAllType) => item.id > 4
                ) ?? null
              }
              handleAddOrUpdateStatusOrder={handleAddOrUpdateStatusOrder}
            />
          )}
          {tabIndex === 4 && (
            <TabCardRejectedForm
              data={companyData.card}
              handleAdd={handleAdd}
              handleUpdate={handleUpdate}
              handleAssignedDriver={handleAssignedDriver}
              updateRejectedCount={updateRejectedCount}
              allOrderStatus={
                allOrderStatus?.filter(
                  (item: orderStatusAllType) => item.id > 5
                ) ?? null
              }
              handleAddOrUpdateStatusOrder={handleAddOrUpdateStatusOrder}
            />
          )}
        </Box>
      </FancyPaper>
      {/*   <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Agregar Filtros</DialogTitle>
        <DialogContent>
          {fields.map((field, index) => (
            <Grid
              item
              xs={12}
              key={field.id}
              sx={{ mt: 2, position: "relative" }}
            >
              <Box display="flex" alignItems="flex-end">
                <Box flexGrow={1}>
                  <Controller
                    name={`filters.${index}.column`}
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        variant="standard"
                        sx={{ minWidth: 120, mr: 2 }}
                      >
                        <InputLabel>Columna</InputLabel>
                        <Select {...field} label="Columna">
                          {columns.map((col: any) => (
                            <MenuItem key={col} value={col}>
                              {col}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={`filters.${index}.value`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={`Valor`}
                        variant="standard"
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ position: "absolute", left: 350, bottom: 10 }}>
                  <IconButton
                    color="error"
                    onClick={() => remove(index)}
                    sx={{ height: 40, width: 40 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              {fields.length > 0 && (
                <Box sx={{ position: "absolute", left: 350, bottom: -15 }}>
                  <IconButton
                    color="primary"
                    onClick={() => append({ column: "", value: "" })}
                    sx={{ height: 40, width: 40 }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
          ))}

          {fields.length === 0 && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button
                startIcon={<AddIcon />}
                onClick={() => append({ column: "", value: "" })}
              >
                Agregar filtro
              </Button>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              mr: 2,
            }}
          >
            Cerrar
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
            sx={{
              mt: 2,
              mr: 2,
            }}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default SettingManagementBiker;
