import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { formatDate } from "../../utils/UtilsFunctions";
import { UrlBase } from "../../url/Urls";

export const getRequestColor = async () => {
    let responseData = {};
    await privateFetch
        .get(
            URL.REQUEST_COLOR_LIST
        )
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);

        });
    return responseData;
};

export const getRequestCarriageBrand = async () => {
    let responseData = {};
    await privateFetch
        .get(
            URL.REQUEST_BRAND_LIST
        )
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);

        });
    return responseData;
};

export const getRequestMeanOfTransport = async () => {
    let responseData = {};
    await privateFetch
        .get(
            URL.REQUEST_TRANSPORT_LIST
        )
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);

        });
    return responseData;
};