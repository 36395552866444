import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  IconButton,
  styled,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { ForgotPasswordType } from "../../types/Login";
import { SVChangePassRequest } from "../../services/Security";

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
  fontWeight: 700,
}));

function ForgotPassword() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { code } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    successMsg,
    setSuccessMsg,
    errorMsg,
    loginProvider,
    resetErrorMsg,
    resetSuccessMsg,
  } = useApp();

  const validationForgotPassword = Yup.object().shape({
    password: Yup.string()
      .required("Contaseña es requerida")
      .min(6, "Contraseña debe tener al menos 8 caracteres")
      .test(
        "password",
        `Formato no es correcto, debe contener al menos 1 mayúsculas, 1 minúsculas, 1 número y 1 carácter especial`,
        (value: any) =>
          value.match(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/
          )?.length > 0
      ),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Contraseñas no coinciden")
      .required("Repetir contraseña es requerido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
  } = useForm<ForgotPasswordType>({
    resolver: yupResolver(validationForgotPassword),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePassword = async (data: ForgotPasswordType) => {
    try {
      setLoading(true);
      const response: any = await SVChangePassRequest({
        id: code,
        password: data.password,
      });
      if (response) {
        setLoading(false);
        setSuccessMsg(response.info);
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any) => {
    await handleChangePassword(data);
  };

  return (
    <>
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name={"password"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  autoComplete="password"
                  {...register("password")}
                  error={errors.password && Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name={"repeatPassword"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Confirmar Contraseña"
                  type={showPassword ? "text" : "password"}
                  autoComplete="repeatPassword"
                  {...register("repeatPassword")}
                  error={
                    errors.repeatPassword && Boolean(errors.repeatPassword)
                  }
                  helperText={
                    errors.repeatPassword && errors.repeatPassword.message
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              sx={{
                mt: 3,
              }}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Typography align="center" sx={{ mt: 6 }} variant="body1">
        Regresar a <LinkTo to="/">inicio de sesión</LinkTo>
      </Typography>
    </>
  );
}

export default ForgotPassword;
