import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Box, Typography, Divider, useTheme, FormControl, InputLabel, Select, MenuItem, Stack, Button } from "@mui/material";
import CardDashboard from "../components/CardDashboard";
import CardDashboardWithButton from '../components/CardDashboardWithButton';
import MopedIcon from '@mui/icons-material/Moped';
import { useApp } from "../hooks/useApp";
import FancyPaper from "../components/FancyPaper";
import {
  getRequestBookingByArea,
  getRequestAveRange
} from "../services/DashboardServices";
import {
  getRequestCoverageExcel,
  getRequestDeliveryStatics,
  getRequestRejectDelivery,
  getRequestSuccessDelivery,
  getRequestTimelinessPercentage,
  getRequestPendingRate,
} from "../services/delivery/DeliveryServices";
import { useForm } from "react-hook-form";
import { StringLiteral } from "typescript";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const SettingReportZones = () => {
  const { setLoading, setErrorMsg } = useApp();
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [successDelivery, setSuccessDelivery] = useState<any>(null);
  const [rejectDelivery, setRejectDelivery] = useState<any>(null);
  const [deliveryStatics, setDeliveryStatics] = useState<any>(null);
  const [deliveryAverage, setDeliveryAverage] = useState<any>(null);
  const [timelinessPercentage, setTimelinessPercentage] = useState<any>(null);
  const [pendingRate, setPendingRate] = useState<any>(null);
  const currentYear = new Date().getFullYear();
  const { handleSubmit } = useForm();

  const handleApplyFilter = () => {
    handleFetch(currentYear)
  };

  useEffect(() => {
    const dataInit = async () => {
      if (currentYear !== null) {
        await handleFetch(currentYear);
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetch = async (id: any) => {
    setLoading && setLoading(true);
    try {
      let dataSuccessDelivery = await getRequestSuccessDelivery(startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD'));
      let dataRejectDelivery = await getRequestRejectDelivery(startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD'));
      let dataDeliveryStatics = await getRequestDeliveryStatics(startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD'));
      let dataAveByRange = await getRequestAveRange(startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD'));
      let dataTimelinessPercentage = await getRequestTimelinessPercentage(startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD'));
      let dataPendingRate = await getRequestPendingRate(startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD'));

      if (dataSuccessDelivery) {
        setSuccessDelivery(dataSuccessDelivery);
      }

      if (dataRejectDelivery) {
        setRejectDelivery(dataRejectDelivery);
      }

      if (dataDeliveryStatics) {
        setDeliveryStatics(dataDeliveryStatics);
      }

      if (dataAveByRange) {
        setDeliveryAverage(dataAveByRange);
      }

      if (dataTimelinessPercentage) {
        setTimelinessPercentage(dataTimelinessPercentage);
      }

      if (dataPendingRate) {
        setPendingRate(dataPendingRate);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchBookingByArea = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let dataBookingByArea = await getRequestBookingByArea(id);
      // if (dataBookingByArea) {
      //   setBookingByArea(dataBookingByArea);
      // }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleDownloadExcel = async () => {
    // Lógica para descargar el archivo Excel
    //getRequestCoverageExcel
    setLoading && setLoading(true);
    try {
      let data = await getRequestCoverageExcel();
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  }

  return (
    <FancyPaper pagetitle={"Reportes / Entregas"}>
      <Grid container spacing={8} sx={{ pb: 8 }}>
        <Grid item md={12} sm={12} xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  format="YYYY-MM-DD"
                />
                <DatePicker
                  label="Fecha de fin"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  format="YYYY-MM-DD"
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={6} my={1}>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              deliveryStatics !== null && deliveryStatics?.deliverySpeed
                ? deliveryStatics?.deliverySpeed + "Min"
                : 0
            }
            title="Velocidad de entrega"
            subtitle=""
            colorIcon="#00800A"
            iconCard={<SpeedOutlinedIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              timelinessPercentage !== null && timelinessPercentage.value
                ? timelinessPercentage.value + "%"
                : 0
            }
            title="Tasa de puntualidad"
            subtitle=""
            colorIcon="#0xFFEBBB00"
            iconCard={<WhereToVoteOutlinedIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              deliveryStatics !== null && deliveryStatics?.lateDeliverySpeed
                ? deliveryStatics?.lateDeliverySpeed + "Min"
                : 0
            }
            title="Velocidad de entrega tardía"
            subtitle=""
            colorIcon="#EBBB00"
            iconCard={<NotListedLocationOutlinedIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              rejectDelivery !== null && rejectDelivery?.value
                ? rejectDelivery?.value + "%"
                : 0
            }
            title="Casos devueltos"
            subtitle=""
            colorIcon="#FF0000"
            iconCard={<AssignmentReturnOutlinedIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              successDelivery !== null && successDelivery?.value
                ? successDelivery?.value + "%"
                : 0
            }
            title="Entrega exitosa"
            subtitle=""
            colorIcon="#FFA500"
            iconCard={<MopedIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              pendingRate !== null && pendingRate?.value
                ? pendingRate?.value
                : 0
            }
            title="Tasa de pendientes"
            subtitle=""
            colorIcon="#00800A"
            iconCard={<PendingActionsOutlinedIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={"98%"}
            title="Up Time"
            subtitle=""
            colorIcon="#EBBB00"
            iconCard={<UpdateOutlinedIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              deliveryAverage !== null && deliveryAverage.value
                ? deliveryAverage.value + "%"
                : 0
            }
            title="Promedio de entregas"
            subtitle=""
            colorIcon="#FFA500"
            iconCard={<GradingOutlinedIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboardWithButton
            value={""}
            title="Reporte cobertura de zonas"
            subtitle=""
            colorIcon="#00800A"
            iconCard={<FileDownloadOutlinedIcon />}
            buttonText="Descargar Excel"
            onButtonClick={handleDownloadExcel}
          />
        </Grid>
      </Grid>
    </FancyPaper>
  );
};

export default SettingReportZones;
