import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import FancyPaper from "../components/FancyPaper";

const NewOrder = () => {
  return (
    <FancyPaper pagetitle="Nueva orden">
      <Grid container>
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Nombre del cliente"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Dirección"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Descripción del paquete"
            variant="standard"
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Button variant="contained">Aceptar</Button>
        </Grid>
      </Grid>
    </FancyPaper>
  );
};

export default NewOrder;
