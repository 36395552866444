import { useState, FC, useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  IconButton,
  styled,
  InputAdornment,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { useApp } from "../../hooks/useApp";
import { SVActiveUserRequest } from "../../services/Security";

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
  fontWeight: 700,
}));

const ActiveRegister: FC = () => {
  const theme = useTheme();
  const { code, email } = useParams();
  const [validateUser, setValidateUser] = useState(false);
  const [message, setMessage] = useState("");

  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    successMsg,
    setSuccessMsg,
    errorMsg,
    loginProvider,
    resetErrorMsg,
    resetSuccessMsg,
  } = useApp();

  const activeUser = async () => {
    try {
      setLoading(true);
      console.log("code", code);
      console.log("username", email);
      if (email && code) {
        console.log("Entra");
        const response = await SVActiveUserRequest(email, code);
        if (response) {
          setLoading(false);
          setValidateUser(true);
        }
      }
    } catch (e: any) {
      console.log(e.message);
      setLoading(false);
      setMessage(e.response.data.message);
    }
  };

  useLayoutEffect(() => {
    activeUser();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center" p={2}>
      {validateUser ? (
        <>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            ¡Ha autenticado y activado su cuenta correctamente!
          </Typography>
          <Button fullWidth component={Link} to="/">
            Iniciar sesión
          </Button>
        </>
      ) : (
        <>
          <Typography variant="body1" color={"error"}>
            ¡Ha ocurrido un error!
          </Typography>
          <Typography variant="subtitle1" color="success.main">
            {message}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default ActiveRegister;
