// src/components/TabCard1Form.tsx
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  TextField,
} from "@mui/material";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import { useForm } from "react-hook-form";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PenIcon } from "../../components/svgicons/SvgIcons";
import FancyTooltip from "../../components/utils/FancyTooltip";
import DeleteModal from "../../components/utils/DeleteModal";
import { useApp } from "../../hooks/useApp";
import ResourceAccess from "../../components/security/ResourceAccess";
import AddOrUpdateManagement from "../../components/utils/AddOrUpdateBiker";
import ChangeStatusModal from "../../components/utils/ChangeStatusModal";
import { itemOrderType, orderStatusAllType } from "./orderTypes";
import { properties } from "../../utils/Properties_es";
import { clientOrderByStatusType } from "../../types/Context";
import { initialStateContent } from "./initialStateOrderByStatus";
import FancyDialogActions from "../../components/utils/FancyDialogActions";

interface TabCard1FormProps {
  data: any;
  handleAdd: any;
  handleUpdate: any;
  updateOnTheWayCount: number;
  allOrderStatus: orderStatusAllType[] | null;
  handleAddOrUpdateStatusOrder: any;
}

const TabCardProgressForm: React.FC<TabCard1FormProps> = ({
  data,
  handleAdd,
  handleUpdate,
  updateOnTheWayCount,
  allOrderStatus,
  handleAddOrUpdateStatusOrder,
}) => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    historyGroupByPackStatus,
    handleHistoryByPackStatus,
    routeAll,
    countTabs,
    setCountTabs,
  } = useApp();

  const [preFilter, setPreFilter] = useState<string>("");
  const [page, setPage] = useState(0);
  const [orderOnTheWay, setOrderOnTheWay] =
    useState<clientOrderByStatusType>(initialStateContent);

  useEffect(() => {
    if (historyGroupByPackStatus !== null) {
      setOrderOnTheWay(historyGroupByPackStatus.onTheWay);
    }
  }, [historyGroupByPackStatus]);

  useEffect(() => {
    const getData = async () => {
      await handleFetchData(0, preFilter);
    };
    getData();
    // eslint-disable-next-line
  }, [updateOnTheWayCount]);

  const { handleSubmit } = useForm();

  const handleFetchData = async (page: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(page);

      let orderData: any = await handleHistoryByPackStatus(
        3,
        page,
        filter,
        authInfo?.company?.id !== 10 ? authInfo?.company?.id : 0
      );
      if (orderData) {
        setOrderOnTheWay(orderData);
        let updateCountTabs = { ...countTabs };
        updateCountTabs.onTheWay = orderData.totalElements;
        setCountTabs(updateCountTabs);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const onSubmit = async (formData: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(formData);
        break;
      case "update":
        await handleUpdate(3, formData);
        break;
      case "change":
        await handleAddOrUpdateStatusOrder(2, formData);
        break;
      default:
        break;
    }
  };

  const handleOpenModal = (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    const id = event.currentTarget.getAttribute("data-id");
    let object = null;
    let destination2 = null;
    if (
      modalAction === "finish" ||
      modalAction === "update" ||
      modalAction === "change" ||
      modalAction === "onTheWay" ||
      modalAction === "delete"
    ) {
      object = orderOnTheWay.content.find((p: any) => p.id === parseInt(id));
    }
    if (modalAction === "map") {
      destination2 = orderOnTheWay.content.find(
        (p: any) => p.id === parseInt(id)
      );
      object =
        destination2?.province?.name +
        ", " +
        destination2?.canton.name +
        ", " +
        destination2?.distrito.name;
    }
    setModalData &&
      setModalData({
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 6,
          pb: 8,
        }}
      >
        <Stack direction="row" alignItems="center">
          <TextField
            placeholder="Filtro de búsqueda"
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "0.2rem 0 0 0.2rem",
              },
              "& fieldset": {
                borderRightWidth: "0",
              },
            }}
            value={preFilter}
            onChange={(e: any) => {
              setPreFilter(e.target.value);
              if (e.target.value === "") {
                handleFetchData(0, "");
              }
            }}
            onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleApplyFilter}
            sx={{
              borderRadius: "0 0.2rem 0.2rem 0!important",
            }}
          >
            Buscar
          </Button>
        </Stack>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Identificador"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Empresa"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Cliente"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Tel 1"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Tel 2"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Zona"}
              </TableCell>
              {/* <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Cantidad producto"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Monto cliente"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Monto mensajero"}
              </TableCell> */}
              <TableCell
                align="center"
                sx={{ backgroundColor: "#EBBB00", color: "white !important" }}
              >
                {"Acciones"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderOnTheWay !== null && orderOnTheWay.content.length > 0 ? (
              orderOnTheWay.content.map((r: itemOrderType) => (
                <TableRow key={r.id}>
                  <TableCell align="center" component="th" scope="row">
                    {r.id}
                  </TableCell>
                  <TableCell align="center">{r.company.name}</TableCell>
                  <TableCell align="center">{r.name}</TableCell>
                  <TableCell align="center">{r.phone}</TableCell>
                  <TableCell align="center">{r.phone1}</TableCell>
                  <TableCell align="center">{r.route.zone.name}</TableCell>
                  {/* <TableCell align="center">5</TableCell>
                  <TableCell align="center">{r.amount}</TableCell>
                  <TableCell align="center">
                    {r.deliveryCourierAmount}
                  </TableCell> */}
                  <TableCell align="center">
                    <ResourceAccess
                      isCode={true}
                      pathOrCode={"MANAGEMENT:WRITE"}
                    >
                      <FancyTooltip
                        title={
                          authInfo?.userRole?.id === 2
                            ? "Ver detalle"
                            : "Editar"
                        }
                        placement="top"
                      >
                        <IconButton
                          aria-label="edit"
                          component="label"
                          color="primary"
                          onClick={handleOpenModal}
                          data-name="onTheWay"
                          data-id={r.id}
                        >
                          {authInfo?.userRole?.id === 2 ? (
                            <VisibilityIcon sx={{ fontSize: "1.5rem" }} />
                          ) : (
                            <PenIcon />
                          )}
                        </IconButton>
                      </FancyTooltip>
                    </ResourceAccess>
                    <ResourceAccess
                      isCode={true}
                      pathOrCode={"MANAGEMENTEDIT:WRITE"}
                    >
                      <FancyTooltip title={"Cambiar estado"} placement="top">
                        <IconButton
                          aria-label="change"
                          component="label"
                          color="primary"
                          onClick={handleOpenModal}
                          data-name="change"
                          data-id={r.id}
                        >
                          <AutorenewIcon sx={{ fontSize: "1.5rem" }} />
                        </IconButton>
                      </FancyTooltip>
                    </ResourceAccess>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {properties.com_parval_label_table_empty}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FancyTablePagination
        count={
          orderOnTheWay?.content?.length > 0
            ? orderOnTheWay?.content?.length
            : 0
        }
        rowsPerPage={orderOnTheWay.size}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalElements={orderOnTheWay.totalElements}
        totalPages={orderOnTheWay.totalPages}
      />
      {(modalData?.modalType === "update" ||
        modalData?.modalType === "finish" ||
        modalData?.modalType === "onTheWay") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
          maxWidth="lg"
        >
          <AddOrUpdateManagement
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            routeAll={routeAll}
          />
        </Dialog>
      )}

      {modalData?.modalType === "change" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <ChangeStatusModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            allOrderStatus={allOrderStatus}
            handleAddOrUpdateStatusOrder={handleAddOrUpdateStatusOrder}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            textChildren={modalData?.modalObject?.nombre}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default TabCardProgressForm;
