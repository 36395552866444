import { useState } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const userRole = [
  { id: 1, role_name: "Administrador" },
  { id: 10, role_name: "Cumplimiento" },
  { id: 11, role_name: "Operaciones" },
  { id: 12, role_name: "Servicio al Cliente" },
  { id: 13, role_name: "Administrador" },
];

interface AddOrUpdateUserTypes {
  id?: number;
  password: string;
  repeatPassword: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
}

const UpdateUserPassModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
}: DialogProps) => {
  const [showPassword, setShowPassword] = useState(false);
  //Validación de datos
  const validation = Yup.object().shape({
    password: Yup.string()
      .required("Contaseña es requerida")
      .test(
        "password",
        `Debe tener mínimo 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial.`,
        (value: any) =>
          value.match(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/
          )?.length > 0
      ),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Contraseñas no coinciden")
      .required("Repetir contraseña es requerido"),
  });

  const defaultValues = {
    id: data.id ?? 0,
    password: data.password ? data.password : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateUserTypes>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {properties.com_parval_label_user_update_pass_title}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"password"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    value={value}
                    autoComplete="password"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("password")}
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"repeatPassword"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Confirmar Contraseña"
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    value={value}
                    autoComplete="repeatPassword"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("repeatPassword")}
                    error={!!errors.repeatPassword}
                    helperText={
                      errors.repeatPassword && errors.repeatPassword.message
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default UpdateUserPassModal;
