import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
  Navigate,
} from "react-router-dom";
import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import DashboardLayout from "../layouts/DashboardLayout";
import DashboardHome from "../pages/DashboardHome";
import Auth from "../pages/session/Auth";
import Login from "../pages/session/Login";
import RememberPassword from "../pages/session/RememberPassword";
import ForgotPassword from "../pages/session/ForgotPassword";
import ActiveRegister from "../pages/session/ActiveRegister";
import ManagementDrivers from "../pages/ManagementDrivers";
import AssignedOrders from "../pages/AssignedOrders";
import CompletedOrders from "../pages/CompletedOrders";
import PendingOrders from "../pages/PendingOrders";
import ManagementRole from "../pages/ManagementRole";
import ManagementUser from "../pages/ManagementUsers";
import SettingAuthority from "../pages/SettingAuthority";
import SettingAuthorityMobile from "../pages/SettingAuthorityMobile";
import SettingManagementBiker from "../pages/SettingManagementBiker";
import SettingAuthorityByRoleManagement from "../pages/SettingAuthorityByRoleManagement";
import SettingAuthorityByRoleManagementApp from "../pages/SettingAuthorityByRoleManagementApp";
import NewOrder from "../pages/NewOrder";
import ManagementInventory from "../pages/maintenance/ManagementInventory";
import ManagementCompany from "../pages/settings/ManagementCompany";
import ManagementRoutes from "../pages/maintenance/ManagementRoutes";
import SettingReportOrders from "../pages/SettingReportOrders";
import SettingReportDrivers from "../pages/SettingReportDrivers";
import SettingReportZones from "../pages/SettingReportZones";
import ManagementBanks from "../pages/maintenance/ManagementBanks";
import CancelSubscription from "../pages/session/CancelSubscription";

export const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/" element={<Auth />}>
        <Route index element={<Login />} />
        <Route path="/rememberPassword" element={<RememberPassword />} />
        <Route
          path="/forgot-password/:code/:email"
          element={<ForgotPassword />}
        />
        <Route
          path="/active-register/:code/:email"
          element={<ActiveRegister />}
        />
      </Route>
      <Route path="/deleteaccount" element={<CancelSubscription />} />
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="home" element={<DashboardHome />} />
        <Route path="drivers" element={<ManagementDrivers />} />
        <Route path="newOrder" element={<NewOrder />} />
        <Route path="assignedOrders" element={<AssignedOrders />} />
        <Route path="completedOrders" element={<CompletedOrders />} />
        <Route path="pendingOrders" element={<PendingOrders />} />
        <Route path="settingroles" element={<ManagementRole />} />
        <Route path="settinguser" element={<ManagementUser />} />
        <Route path="settingauthority" element={<SettingAuthority />} />
        <Route
          path="settingAuthorityMobile"
          element={<SettingAuthorityMobile />}
        />
        <Route path="settingmanagement" element={<SettingManagementBiker />} />
        <Route path="settingReportDrivers" element={<SettingReportDrivers />} />
        <Route path="settingReportOrders" element={<SettingReportOrders />} />
        <Route path="settingReportZones" element={<SettingReportZones />} />
        <Route
          path="authoritiesbyrole/:roleId"
          element={<SettingAuthorityByRoleManagement />}
        />
        <Route
          path="authoritiesbyroleapp/:roleId"
          element={<SettingAuthorityByRoleManagementApp />}
        />
        <Route path="inventory" element={<ManagementInventory />} />
        <Route path="company" element={<ManagementCompany />} />
        <Route path="routes" element={<ManagementRoutes />} />
        <Route path="banks" element={<ManagementBanks />} />
      </Route>
    </Route>
  )
);
