import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Box, Typography, Divider, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BusinessIcon from "@mui/icons-material/Business";
import EventIcon from "@mui/icons-material/Event";
import ComparativeGraph from "../components/ComparativeGraph";
import CardDashboard from "../components/CardDashboard";
import PieChartDashboard from "../components/PieChartDashboard";
import MopedIcon from '@mui/icons-material/Moped';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useApp } from "../hooks/useApp";
import FancyPaper from "../components/FancyPaper";
import {
  getRequestCompanyByCategory,
  getRequestBookingByArea,
  getRequestTotalSales,
  getRequestPercentageCoverage,
  getRequestAveRange,
  getRequestSalesByMonth,
  getRequestAveHours
} from "../services/DashboardServices";
import { useForm } from "react-hook-form";
import { StringLiteral } from "typescript";
import dayjs from "dayjs";
import { getRequestOrders, getRequestOrdersDashboard } from "../services/report/ReportServices";

const DashboardHome = () => {
  const { setLoading, setErrorMsg } = useApp();
  const [totalSales, setTotalSales] = useState<any>(null);
  const [percentageCoverage, setPercentageCoverage] = useState<any>(null);
  const [companyByCategory, setCompanyByCategory] = useState<any>(null);
  const [salesByMonth, setSalesByMonth] = useState<any>([]);
  const [aveRange, setAveRange] = useState<any>(null);
  const [aveHours, setAveHours] = useState<any>(null);
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState<string>("global");
  const { handleSubmit } = useForm();

  const getDates = (filter: any) => {
    if (filter === "mes") {
      return {
        firstDay: dayjs().startOf('month').format('YYYY-MM-DD'),
        lastDay: dayjs().endOf('month').format('YYYY-MM-DD'),
      };
    } else {
      return {
        firstDay: dayjs().startOf('year').format('YYYY-MM-DD'),
        lastDay: dayjs().endOf('year').format('YYYY-MM-DD'),
      };
    }
  };

  const firstDayYear = dayjs().startOf('year').format('YYYY-MM-DD');
  const lastDayYear = dayjs().endOf('year').format('YYYY-MM-DD');


  useEffect(() => {
    const dataInit = async () => {
      if (currentYear !== null) {
        await handleFetch(currentYear);
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, [currentYear, selectedMonth]);

  const handleFetch = async (id: any) => {
    const { firstDay, lastDay } = getDates(selectedMonth);
    setLoading && setLoading(true);
    try {
      let dataTotalSales = await getRequestTotalSales(firstDay, lastDay);
      let dataSalesByMonth = await getRequestSalesByMonth(currentYear);
      let dataPercentageCoverage = await getRequestPercentageCoverage();
      let dataAveByRange = await getRequestAveRange(firstDay, lastDay);
      let dataOrdersByClient = await getRequestOrdersDashboard(0, firstDayYear, lastDayYear);
      let dataAveHours = await getRequestAveHours(firstDay, lastDay);
      
      //getRequestAveHours
      if (dataTotalSales) {
        setTotalSales(dataTotalSales);
      }
      if (dataPercentageCoverage) {
        setPercentageCoverage(dataPercentageCoverage);
      }
      if (dataSalesByMonth) {
        setSalesByMonth(dataSalesByMonth);
      }
      if (dataAveByRange) {
        setAveRange(dataAveByRange);
      }
      if (dataAveHours) {
        setAveHours(dataAveHours);
      }
      if (dataOrdersByClient) {
        setCompanyByCategory(dataOrdersByClient.content);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };
  const handleFetchCompanyByCategory = async () => {
    setLoading && setLoading(true);
    try {
      let dataCompanyByCategory = await getRequestCompanyByCategory();
      if (dataCompanyByCategory) {
        setCompanyByCategory(dataCompanyByCategory);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchBookingByMonth = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let dataSalesByMonth = await getRequestSalesByMonth(currentYear);
      if (dataSalesByMonth) {
        setSalesByMonth(dataSalesByMonth);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchBookingByArea = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let dataBookingByArea = await getRequestBookingByArea(id);
      // if (dataBookingByArea) {
      //   setBookingByArea(dataBookingByArea);
      // }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };


  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const dataName = e.target.name;
    switch (dataName) {
      case "companyByCategory":
        await handleFetchCompanyByCategory();
        break;
      case "bookingByMonth":
        await handleFetchBookingByMonth("1");
        break;
      case "bookingByArea":
        await handleFetchBookingByArea("1");
        break;
      default:
        break;
    }
  };

  const transformSalesData = (data: any) => {
    const months = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    return [
      {
        data: months.map(month => data[month] || 0),
        name: String(currentYear)
      }
    ];
  };

  const transformCompanyData = (data: any) => {
    if (data && data) {
      const transformedData = data.map((item: any) => [item.name, item.count]);
      return [["Category", "Count"], ...transformedData];
    }
    return [["Category", "Count"]];
  };

  return (
    <FancyPaper pagetitle={"Inicio"}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <FormControl style={{ minWidth: 200 }}>
          <InputLabel id="month-select-label">Filtro</InputLabel>
          <Select
            labelId="month-select-label"
            id="month-select"
            value={selectedMonth}
            label="Filtro"
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <MenuItem value="global">Anual</MenuItem>
            <MenuItem value="mes">Mes</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={6} my={1}>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              totalSales !== null && totalSales.count
                ? totalSales.count
                : 0
            }
            title="Cantidad de entregas"
            subtitle=""
            colorIcon="#00800A"
            iconCard={<TrendingUpIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              percentageCoverage !== null && percentageCoverage
                ? percentageCoverage + "%"
                : 0
            }
            title="Cobertura de zonas"
            subtitle=""
            colorIcon="#00800A"
            iconCard={<CreditCardIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              aveRange !== null && aveRange.value
                ? aveRange.value
                : 0
            }
            title="Promedio de entregas"
            subtitle=""
            colorIcon="#00800A"
            iconCard={<EventIcon />}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardDashboard
            positive
            value={
              aveHours !== null && aveHours.value
                ? aveHours.value
                : 0
            }
            title="Promedio de horas en mensajería"
            subtitle=""
            colorIcon="#00800A"
            iconCard={<MopedIcon />}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          {salesByMonth !== null && (
            <ComparativeGraph
              title="Pedidos por mes"
              chartSeries={transformSalesData(salesByMonth)}
              name={"bookingByMonth"}
              handleClick={handleSubmit(onSubmit)}
            />
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          {companyByCategory !== null && (
            <PieChartDashboard
              title="Pedidos por cliente"
              data={transformCompanyData(companyByCategory)}
              titleChart=" "
              name={"companyByCategory"}
              handleClick={handleSubmit(onSubmit)}
            />
          )}
        </Grid>
      </Grid>
    </FancyPaper>
  );
};

export default DashboardHome;
