import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import {
  itemOrderType,
  saveSerialsType,
} from "../../pages/tabsManagement/orderTypes";
import { UrlBase } from "../../utils/Urls";

export const getRequestHistoryGroupByPackStatus = async (
  startDate: string,
  endDate: string,
  page: number,
  companyId?: number
) => {
  try {
    const response = await privateFetch.get(
      URL.FIND_HISTORY_GROUP_BY_PACKAGE_STATUS(
        startDate,
        endDate,
        page,
        UrlBase.registration_x_page,
        companyId
      )
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestHistoryByPackStatus = async (
  packageStatus: number,
  startDate: string,
  endDate: string,
  page: number,
  filter: string,
  companyId?: number
) => {
  try {
    const response = await privateFetch.get(
      URL.FIND_HISTORY_BY_PACKAGE_STATUS(
        packageStatus,
        startDate,
        endDate,
        page,
        UrlBase.registration_x_page,
        filter,
        companyId
      )
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const addOrUpdClientOrder = async (data: itemOrderType) => {
  let responseData = {};
  await privateFetch
    .post(URL.SAVE_CLIENT_ORDER, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const deleteClientOrder = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data,
  };

  await privateFetch
    .delete(URL.DELETE_CLIENT_ORDER, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error);
      throw new Error(error.message);
    });
  return responseData;
};

/**
 ***** Order Detail url
 **/

export const getRequestOrderDetail = async (id: string) => {
  try {
    const response = await privateFetch.get(URL.ORDER_DETAIL_ID(id));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const addOrUpdOrderDetail = async (data: saveSerialsType) => {
  let responseData = {};
  await privateFetch
    .post(URL.ORDER_DETAIL_SAVESERIALS, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.data.message);
    });
  return responseData;
};

export const getOrderDetail = async (id: number) => {
  try {
    const response = await privateFetch.get(URL.ORDER_DETAIL_FIND_BY_ID_CLIENT_ORDER(id));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const deleteOrderDetail = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data,
  };

  await privateFetch
    .delete(URL.ORDER_DETAIL_DELETE, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error);
      throw new Error(error.message);
    });
  return responseData;
};

export const addOrUpdOneOrderDetail = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.ORDER_DETAIL_SAVE, JSON.stringify(data.serial), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.data.message);
    });
  return responseData;
};


//Actualizar status de la orden
export const addOrUpdOrderStatus = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.ORDER_STATUS_SAVE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

//Obtiene todos los estados por los que atraviesa el pedido
export const getRequestOrderStatusAll = async () => {
  try {
    const response = await privateFetch.get(URL.ORDER_STATUS);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

//Guardar rutas de imagenes en clientOrder
export const addOrUpdOrderDocuments = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.SAVE_ORDER_DOCUMENTS, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const deleteOrderDocuments = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data,
  };

  await privateFetch
    .delete(URL.DELETE_ORDER_DOCUMENTS, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error);
      throw new Error(error.message);
    });
  return responseData;
};
