import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import { properties } from "../../utils/Properties_es";
import { phoneRegExp } from "../../utils/Validations";
import FancyDialogActions from "../../components/utils/FancyDialogActions";

interface AddOrUpdateCompanyTypes {
  id?: number;
  name: string;
  description: string;
  phone_one: string;
  phone_two?: string;
  email: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateCompany = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  //Validación de datos
  const validation = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required("Campo es requerido"),
    description: Yup.string().required("Campo es requerido"),
    phone_one: Yup.string()
      .required("Campo es requerido")
      .matches(phoneRegExp, {
        message: "Teléfono no es valido",
        excludeEmptyString: true,
      }),
    phone_two: Yup.string().matches(phoneRegExp, {
      message: "Teléfono no es valido",
      excludeEmptyString: true,
    }),
    email: Yup.string()
      .required("Campo es requerido")
      .email("Email es invalido"),
  });
  const defaultValues = {
    id: data?.id ?? 0,
    name: data?.name ?? "",
    description: data?.description ?? "",
    phone_one: data?.phoneNumber1 ?? "",
    phone_two: data?.phoneNumber2 ?? "",
    email: data?.email ?? "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<AddOrUpdateCompanyTypes>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar empresa" : "Actualizar empresa"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre empresa"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="off"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={
                      errors.name && errors.name.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción de la empresa"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="off"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"phone_one"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Teléfono"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="phone_one3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone_one")}
                    error={errors.phone_one && Boolean(errors.phone_one)}
                    helperText={errors.phone_one && errors.phone_one.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"phone_two"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Otro teléfono"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="off"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone_two")}
                    error={errors.phone_two && Boolean(errors.phone_two)}
                    helperText={errors.phone_two && errors.phone_two.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Correo electrónico"
                    type="mail"
                    variant="standard"
                    value={value}
                    autoComplete="off"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("email")}
                    error={errors.email && Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                name={"contactName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre de contacto"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="contactName3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("contactName")}
                    error={errors.contactName && Boolean(errors.contactName)}
                    helperText={
                      errors.contactName && errors.contactName.message
                    }
                  />
                )}
              />
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateCompany;
