import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { emit } from "process";

export const getRequest = async (page: number,  filter:string,) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_COMPANY(page,UrlBase.registration_x_page,filter));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestCompany = async (page: number,  filter:string,) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_COMPANY(page,1000,filter));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestPriceZone = async (page: number,  id:any,) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_COMPANY_PRICE_ZONE(page,UrlBase.registration_x_page,id));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createRequestZones = async (data: any) => {
  let responseData = {};

  const requestBody = {
    company: data.company,
    zone: data.zone,
    clientAmount: data.clientAmount,
    deliveryCourierAmount: data.deliveryCourierAmount,
    thirdPartyShippingAmount: data.thirdPartyShippingAmount,
  };

  await privateFetch
    .post(URL.REQUEST_COMPANY_CREATE_ZONE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_save_error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ROLE(id, UrlBase.registration_x_page), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const findByIdRequestById = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ROLE_BY_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};

  const requestBody = {
    user: data.user,
    name: data.name,
    description: data.description,
    phoneNumber1: data.phone_one,
    phoneNumber2: data.phone_two,
    email: data.email,
    status: 0
  };
  await privateFetch

    .post(URL.REQUEST_COMPANY_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_save_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    user: data.user,
    name: data.name,
    description: data.description,
    phoneNumber1: data.phone_one,
    phoneNumber2: data.phone_two,
    email: data.email,
    status: 0
  };
  await privateFetch
    .post(URL.REQUEST_COMPANY_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};


export const deleteRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id

  };

  await privateFetch
    .delete(URL.REQUEST_COMPANY_DELETE, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error)
    });
  return responseData;
};
