import { useState, useEffect } from "react";
import ReactMapGL, { Source, Layer, Marker } from "react-map-gl";
import route from "./fakeRoutes.json";
import "mapbox-gl/dist/mapbox-gl.css";
import { getLatAndLngtAddress } from "../../services/order/OrderRatingService";
import { Typography } from "@mui/material";

interface locationsType {
  latitude: number;
  longitude: number;
  date: string;
}

interface MapBoxTrakingOrderTpe {
  locations: locationsType[];
  origin: string;
  destination: string;
}

const fake = [
  {
    lng: -84.103643,
    lat: 9.929327,
  },
  {
    lng: -84.100147,
    lat: 9.928875,
  },
  {
    lng: -84.098691,
    lat: 9.92879,
  },
  {
    lng: -84.097952,
    lat: 9.930283,
  },
];

const initialData = {
  lng: 0,
  lat: 0,
};

const MapBoxTrakingOrder = ({
  locations,
  origin,
  destination,
}: MapBoxTrakingOrderTpe) => {
  console.log("locations: ", locations);
  const [originCoordinates, setOriginCoordinates] = useState(initialData);
  const [destinationCoordinates, setDestinationCoordinates] =
    useState(initialData);

  const viewPort = {
    width: "100%",
    height: 600,
    mapStyle: "mapbox://styles/mapbox/streets-v9",
  };

  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiYml0Y29kZWVudGVycHJpc2UiLCJhIjoiY2x5NTluMG9jMGIxYjJscTFnZjJpaDBrbyJ9.KRsJYaKu2RRvEa8hAYiwWA";
  //@ts-ignore
  const mappedRoute = locations?.map(
    (point: any) => [point.longitude, point.latitude] as [number, number]
  );

  /* const mappedRoute = fake.map(
    (point) => [point.lng, point.lat] as [number, number]
  ); */
  /* const mappedRoute = route.points.map(
    (point) => [point.lng, point.lat] as [number, number]
  ); */

  const dataOne = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: mappedRoute,
    },
  };

  /*  const handleLatAndLngtAddress = async (address: string) => {
    try {
      const data = await getLatAndLngtAddress(address, MAPBOX_TOKEN);
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let dataO = null;
    const getData = async () => {
      dataO = await handleLatAndLngtAddress(origin);
      console.log("dataO: ", dataO);
      if (dataO.features[0].geometry.coordinates.length > 0) {
        setOriginCoordinates({
          lng: dataO.features[0].geometry.coordinates[0],
          lat: dataO.features[0].geometry.coordinates[1],
        });
      }
    };
    if (origin !== "") {
      getData();
    }
  }, [origin]);

  useEffect(() => {
    let dataD = null;
    const getData = async () => {
      dataD = await handleLatAndLngtAddress(destination);
      console.log("dataD: ", dataD);
      if (dataD.features[0].geometry.coordinates.length > 0) {
        setDestinationCoordinates({
          lng: dataD.features[0].geometry.coordinates[0],
          lat: dataD.features[0].geometry.coordinates[1],
        });
      }
    };
    if (destination !== "") {
      getData();
    }
  }, [destination]); */

  return (
    <ReactMapGL
      {...viewPort}
      mapboxAccessToken={MAPBOX_TOKEN}
      initialViewState={{
        longitude: locations[0].longitude,
        //longitude: -84.1028053,
        latitude: locations[0].latitude,
        //latitude: 9.9266929,
        zoom: 13,
      }}
    >
      <Source
        id="polylineLayer"
        type="geojson"
        data={dataOne}
        /* data={
          locations.length === 0
            ? [originCoordinates, destinationCoordinates]
            : dataOne
        } */
      >
        <Layer
          id="lineLayer"
          type="line"
          source="my-data"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "#000000",
            "line-width": 5,
          }}
        />
      </Source>
      <Marker
        longitude={mappedRoute[0][0]}
        latitude={mappedRoute[0][1]}
        //anchor="bottom"
        color="green"
      />
      <Marker
        longitude={mappedRoute[0][0]}
        latitude={mappedRoute[0][1]}
        anchor="top"
      >
        <Typography variant="body1" color="error">
          Inicio
        </Typography>
      </Marker>
      <Marker
        longitude={mappedRoute[mappedRoute.length - 1][0]}
        latitude={mappedRoute[mappedRoute.length - 1][1]}
        color="yellow"
      />
      <Marker
        longitude={mappedRoute[mappedRoute.length - 1][0]}
        latitude={mappedRoute[mappedRoute.length - 1][1]}
        anchor="top"
      >
        <Typography variant="body1" color="error">
          Fin
        </Typography>
      </Marker>
    </ReactMapGL>
  );
};

export default MapBoxTrakingOrder;
