import { useState, useEffect, useMemo, forwardRef, Fragment } from "react";
import {
  Link,
  LinkProps,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  Box,
  Drawer,
  Divider,
  IconButton,
  List,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  styled,
  CssBaseline,
  MenuItem,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import { MENUITEMS } from "../utils/Const";
import theme from "../theme/theme";
import { useApp } from "../hooks/useApp";
import Loading from "../components/Loading";
import { sideNavWidth, topBarHeight } from "../utils/Const";
import ResourceAccess from "../components/security/ResourceAccess";
import { ReactComponent as Logo } from "../resources/images/asap-blanco.svg";
import MessageManager from "../components/MessageManager";
import { CONSTANT } from "../utils/UrlContants";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const WrapperDiv = styled("div")(({ theme }) => ({
  background: theme.palette.secondary.main,
}));

const IconHamburger = styled("i")(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: "1px",
  content: '""',
  cursor: "pointer",
  display: "block",
  height: "3px",
  transition: "background .1s ease-in-out,color .1s ease-in-out",
  width: "24px",
  position: "relative",
  "&:before,&:after": {
    background: theme.palette.primary.main,
    borderRadius: "1px",
    content: '""',
    cursor: "pointer",
    display: "block",
    height: "3px",
    transition: "background .1s ease-in-out,color .1s ease-in-out",
    position: "absolute",
  },
  "&:before": {
    top: "-7.5px",
    width: "24px",
  },
  "&:after": {
    bottom: "-7.5px",
    width: "16px",
  },
}));

const IconNestedLink = styled(ListItemIcon)(({ theme }) => ({
  color: "inherit",
  "&:before": {
    content: '"→"',
    display: "inline-block",
    left: "0",
    position: "relative",
    transform: "translateX(0px)",
    transition: "all 0.1s ease 0s",
  },
}));

const ListItemTextNested = styled(ListItemText)(({ theme }) => ({
  "& span": {
    fontSize: "90%",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    marginLeft: `-${sideNavWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: "none",
  padding: "0",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    ...(open && {
      width: `calc(100% - ${sideNavWidth}px)`,
      marginLeft: `${sideNavWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Footer = styled("footer")(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
}));

function ListItemLink(props: any) {
  const { to } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} color="greyDue.main" />
      )),
    [to]
  );

  return <ListItem {...props} component={to ? renderLink : "button"} />;
}

const filterByPermissions = (items: any[], permission: string): any[] => {
  const elements = items.filter((item) =>
    item.permissions.includes(permission)
  );
  elements.forEach((el, index) => {
    if (el.expand) {
      elements[index] = {
        ...el,
        children: filterByPermissions(el.children, permission),
      };
    }
  });
  return elements;
};

const DashboardLayout = () => {
  const {
    authInfo,
    authenticate,
    errorMsg,
    successMsg,
    logoutProvider,
    resetErrorMsg,
    resetSuccessMsg,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
  } = useApp();
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const user = {
    is_superuser: true,
    is_client: false,
    is_company: false,
  };
  const menuItems = getMenuItems();
  const [expanded, setExpanded] = useState<boolean[]>(
    new Array(menuItems.length).fill(false)
  );

  function getMenuItems() {
    let menuItems = [...MENUITEMS];
    if (user.is_superuser) {
      menuItems = filterByPermissions(menuItems, "is_superuser");
    }
    return menuItems;
  }

  const handleClick = (index: number, item: any) => {
    if (item.expand) {
      setExpanded(
        expanded.map((o, i) => {
          if (i === index) {
            return !o;
          }
          return o;
        })
      );
    }
  };

  const handleSelected = (item: any) => {
    return item.to === location.pathname;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <WrapperDiv>
      <Toolbar
        sx={{
          justifyContent: "center",
          paddingBottom: "60px",
          paddingTop: "30px",
          "&:after": {
            content: `""`,
            position: "absolute",
            background: "#019811",
            width: "50%",
            height: "260px",
            zIndex: "0",
            top: "-60%",
            right: "-20%",
            transform: "rotate(-45deg)",
          },
          "& svg": {
            zIndex: "1",
          },
        }}
      >
        {/* <img src={Logo} alt="ASAP Mensajeria" width="150" /> */}
        <Logo />
      </Toolbar>
      <Divider sx={{ mb: theme.spacing(3.25) }} />
      <Typography variant="h5" align="center" color="primary.contrastText">
        Portal Administrativo
      </Typography>
      <Divider sx={{ mt: theme.spacing(3.25) }} />
      <List sx={{ pt: 0, zIndex: "2" }}>
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            {item.label === "Cerrar sesión" ? (
              <ListItemLink
                button
                onClick={logoutProvider}
                selected={handleSelected(item)}
                to={item.to}
                sx={{
                  borderBottom: `1px solid ${theme.palette.text.secondary}`,
                  padding: ".625rem 1.125rem .625rem 1.725rem",
                }}
              >
                <ListItemIcon
                  style={{
                    color: theme.palette.primary.contrastText,
                    fontSize: ".8125rem",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemLink>
            ) : (
              <>
                <ResourceAccess
                  isCode={true}
                  pathOrCode={`${item.permissions[1]}`}
                >
                  <ListItemLink
                    button
                    onClick={() => handleClick(index, item)}
                    selected={handleSelected(item)}
                    to={item.to}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.text.secondary}`,
                      padding: ".625rem 1.125rem .625rem 1.725rem",
                    }}
                  >
                    <ListItemIcon
                      style={{
                        color: theme.palette.primary.contrastText,
                        fontSize: ".8125rem",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                    {item.expand ? (
                      expanded[index] ? (
                        <ExpandLess sx={{ color: "primary.contrastText" }} />
                      ) : (
                        <ExpandMore sx={{ color: "primary.contrastText" }} />
                      )
                    ) : null}
                  </ListItemLink>
                </ResourceAccess>

                {item.expand && (
                  <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                      {item.children.map((child) => (
                        <Fragment key={child.label}>
                          <ResourceAccess
                            pathOrCode={`/${child.to}`}
                            isCode={false}
                          >
                            <ListItemLink
                              button
                              //key={child.label}
                              to={child.to}
                              selected={child.to === location.pathname}
                              sx={{
                                borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
                                padding: ".625rem 1.5rem .625rem 2.35rem",
                              }}
                            >
                              <IconNestedLink
                                sx={{
                                  color: theme.palette.primary.contrastText,
                                }}
                              />
                              <ListItemTextNested primary={child.label} />
                            </ListItemLink>
                          </ResourceAccess>
                        </Fragment>
                      ))}
                    </List>
                  </Collapse>
                )}
              </>
            )}
          </Fragment>
        ))}
      </List>
    </WrapperDiv>
  );

  //validate auth
  if (!authenticate) {
    console.log(authenticate);
    console.log("navigate es");
    navigate("/", { replace: true });
  }

  return (
    <>
      {isLoading && <Loading />}
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      <Box
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <AppBar position="fixed" open={!mobileOpen}>
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                px: 6,
                minHeight: "54px",
              },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <IconHamburger />
            </IconButton>
            <Typography
              color="inherit"
              sx={{ fontSize: "1rem", flexGrow: 1 }}
              noWrap
              component="div"
            >
              {authInfo?.userRole?.id === 2 ? authInfo?.company?.name :  "ASAP Mensajería"}
            </Typography>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                sx={{
                  "&:hover, &:focus": {
                    background: "transparent",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                }}
              >
                <PersonIcon sx={{ fontSize: "24px" }} color="primary" />
                <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                  {authInfo?.name}
                </Typography>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          className="ana"
          sx={{
            width: { sm: sideNavWidth },
            flexShrink: { sm: 0 },
            background: theme.palette.secondary.main,
            overflow: "hidden",
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              background: theme.palette.secondary.main,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: sideNavWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="persistent"
            className="ana2"
            sx={{
              display: { xs: "none", sm: "block" },
              background: theme.palette.secondary.main,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: sideNavWidth,
                background: theme.palette.secondary.main,
                overflow: "hidden",
                "&:after": {
                  content: `""`,
                  position: "absolute",
                  background: "#019811",
                  width: "260px",
                  height: "360px",
                  zIndex: "0",
                  bottom: "0",
                  transform: "rotate(45deg) translate(80%, 0%)",
                },
              },
            }}
            open={!mobileOpen}
          >
            {drawer}
          </Drawer>
        </Box>
        <Main open={!mobileOpen}>
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                minHeight: "54px",
              },
            }}
          />
          <Box sx={{ flex: "1 1" }}>
            <Outlet />
          </Box>
          <Footer>
            <Typography variant="body2">
              Copyright 2024. Todos los derechos reservados.
            </Typography>
          </Footer>
        </Main>
      </Box>
    </>
  );
};

export default DashboardLayout;
