import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestProvince = async ()=> {
  try {
    const response = await privateFetch.get(URL.REQUEST_LOCATION());
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestCanton = async (id: any)=> {
  try {
    const response = await privateFetch.get(URL.REQUEST_CANTON(id));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestDistrict = async (id: any)=> {
  try {
    const response = await privateFetch.get(URL.REQUEST_DISTRICT(id));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestZone = async ()=> {
  try {
    const response = await privateFetch.get(URL.REQUEST_ZONE());
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestRole = async () => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_ROLE2(null)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_role_find_error);
      throw new Error(error);
    });
  return responseData;
};


export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ROLE(id, UrlBase.registration_x_page), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const findByIdRequestById = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ROLE_BY_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  console.log(data)
  let responseData = {};

  const requestBody = {
    roleName: data.roleName,
    description: data.description,
    user: data.userCreated,
  };
  await privateFetch

    .post(URL.REQUEST_ROLE_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_save_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    roleName: data.roleName,
    description: data.description,
    user: data.user,

  };
  await privateFetch
    .post(URL.REQUEST_ROLE_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};


export const deleteRequest = async (id: any) => {
  let responseData = {};
  const requestBody = {
    id: id,

  };

  await privateFetch
    .delete(URL.REQUEST_ROLE_DELETE, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error)
    });
  return responseData;
};
