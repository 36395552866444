import { URL } from "../utils/UrlConstants";
import { privateFetch } from "../utils/CustomFetch.js";
import { CONSTANT } from "../utils/Constants";
import { formatDate } from "../utils/UtilsFunctions";

export const getRequestTotalSales = async (startDate:any, endDate:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_TOTAL_SALES(startDate,endDate))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestPercentageCoverage = async () => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_COVERAGE_PERCENTAGE())    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};


export const getRequestSalesByDay = async (year:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_SALES_BY_DAY(year))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestAveRange = async (startDate:any, endDate:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_AVE_RANGE(startDate,endDate))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestAveHours = async (startDate:any, endDate:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_AVE_HOURS(startDate,endDate))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};



export const getRequestTotalCompany = async () => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_TOTAL_COMPANY)    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestSalesByMonth = async (year:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_SALES_BY_MONTH(year))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestSalesByClient = async (year:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_SALES_BY_CLIENTS(year))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestTotalUser = async (id:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_TOTAL_USERS(id))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};


export const getRequestCompanyByCategory = async () => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_COMPANY_BY_CATEGORY)    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestBookingByMonth = async (id:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_BOOKING_BY_MONTH(id))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestBookingByArea = async (id:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_BOOKING_BY_AREA(id))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestBookingApproved = async (id:any ) => {
  let responseData = {}; 
 console.log(typeof id)
  await privateFetch 
    .get(URL.REQUEST_BOOKING_APPROVED(id))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};





