import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";

/**Subir las imagenes al storage */
export const uploadDocuments = async (data: any) => {
  let responseData = {};
  console.log(data);
  await privateFetch
    .post(URL.BLOB_STORAGE_UPLOAD, data, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.message);
    });
  return responseData;
};

export const deleteDocuments = async (fileName: string) => {
  let responseData = {};

  await privateFetch
    .delete(URL.DELETE_BLOB_STORAGE(fileName))

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error);
      throw new Error(error.message);
    });
  return responseData;
};
