import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls"
import { AuthorityResponse } from "./AuthorityInterfaces";

export const getRequestMobile = async (filter: string, page: number, size?: number) => {
    let responseData = {};
    await privateFetch
        .get(
            URL.REQUEST_AUTHORITY_MOBILE_LIST(
                filter,
                page,
                //@ts-ignore
                size ? size : UrlBase.registration_x_page,
            )
        )
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error("Error en consulta de permisos");
        });
    return responseData;
};

export const getRequestNoAdded = async (roleId: number, page: number, filter: string, size?: number): Promise<AuthorityResponse> => {
    let responseData = {} as AuthorityResponse;

    await privateFetch
        .get(
            URL.REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST(
                roleId,
                page,
                //@ts-ignore
                size || size === 0 ? size : UrlBase.registration_x_page,
                filter
            )
        )
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error("Error en consulta de permisos");
        });
    return responseData;
};

export const findByIdRequest = async (id: any) => {
    let responseData = {};
    await privateFetch
        .get(URL.REQUEST_AUTHORITY(id), {})
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch(async (error) => {
            throw new Error("Error en consulta de permisos por ID");
        });
    return responseData;
};

export const createRequest = async (data: any) => {
    let responseData = {};
    const params = new URLSearchParams([
        ["code", data.code],
        ["createdBy", data.createdBy],
        ["description", data.description],
        ["path", data.path],
        ["platforms", "MOBILE"],
    ]);

    await privateFetch
        .post(
            URL.REQUEST_AUTHORITY(null),
            {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            },
            { params }
        )
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch(async (error) => {
            throw new Error("Error en proceso de guardar permiso");
        });
    return responseData;
};

export const updateRequest = async (data: any) => {
    let responseData = {};
    const params = new URLSearchParams([
        ["code", data.code],
        ["modifiedBy", data.modifiedBy],
        ["description", data.description],
        ["path", data.path],
        ["platforms", "MOBILE"],
    ]);
    await privateFetch
        .put(
            URL.REQUEST_AUTHORITY(data.id),
            {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            },
            { params }
        )
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch(async (error) => {
            throw new Error("Error en proceso de actualizar permiso");
        });
    return responseData;
};

export const deleteRequest = async (id: any) => {
    let responseData = {};
    await privateFetch
        .delete(URL.REQUEST_AUTHORITY(id), {})
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch(async (error) => {
            throw new Error("Error en proceso de eliminar permiso");
        });
    return responseData;
};

