import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from "@mui/material";

interface DialogActionsType {
  handleAccept: () => void;
  cancelModal: () => void;
  textAcceptButton?: string;
}

const FancyDialogActions = ({
  handleAccept,
  cancelModal,
  textAcceptButton,
}: DialogActionsType) => {
  return (
    <>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleAccept}
        >
          {textAcceptButton ?? "Aceptar"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {"Cancelar"}
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default FancyDialogActions;
