import React, { useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TableFooter,
  FormControl,
  InputLabel
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from 'dayjs';
import FancyPaper from "../components/FancyPaper";
import { useApp } from '../hooks/useApp';
import { getRequestCompany } from '../services/company/CompanyService';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import { getRequestOrders, getRequestOrdersExcel } from '../services/report/ReportServices';
//import * as XLSX from 'xlsx';

const SettingReportOrders = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [reportData, setReportData] = useState<any>([]);
  const [companyData, setCompanyData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  useEffect(() => {
    const dataInit = async () => {
      //await handleFetchData(0, "");
      handleDataCompany(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleApplyFilter = async () => {
    await handleFetchData(0, preFilter);

  };

  console.log(selectedCompany)

  const handleFetchData = async (
    currentPage: number,
    filter: string,
    applyRole?: any
  ) => {
    const start = startDate ? startDate.format('YYYY-MM-DD') : '';
    const end = endDate ? endDate.format('YYYY-MM-DD') : '';
    const companyId = selectedCompany ? selectedCompany.id : null;
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);
      //getRequestOrders
      // try {
      let data = await getRequestOrders(currentPage, start, end, companyId);
      if (data) {
        setReportData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleDataCompany = async (
    currentPage: number,
    filter: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequestCompany(currentPage, filter);
      if (data) {
        setCompanyData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const exportToExcel = async () => {
    //getRequestOrdersExcel
    const start = startDate ? startDate.format('YYYY-MM-DD') : '';
    const end = endDate ? endDate.format('YYYY-MM-DD') : '';
    const companyId = selectedCompany ? selectedCompany.id : null;
    setLoading && setLoading(true);
    try {
      let data = await getRequestOrdersExcel(0, start, end, companyId);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <FancyPaper pagetitle="Reportes / Pedidos">
      <Grid container spacing={8} sx={{ pb: 8 }}>
        <Grid item md={12} sm={12} xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToExcel}
              sx={{
                borderRadius: "0.2rem 0.2rem 0.2rem 0.2rem!important",
                padding: "6px 16px!important",
              }}
            >
              Descargar en Excel
            </Button>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  format="YYYY-MM-DD"
                />
                <DatePicker
                  label="Fecha de fin"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  format="YYYY-MM-DD"
                />
              </LocalizationProvider>
              <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                <InputLabel
                  id="empresa-label"
                  sx={{
                    '&:not(.MuiInputLabel-shrink)': {
                      top: '-10px',
                    }
                  }}
                >
                  Empresa
                </InputLabel>
                <Select
                  labelId="empresa-label"
                  label="Empresa"
                  value={selectedCompany}
                  onChange={(e) => setSelectedCompany(e.target.value)}
                >
                  <MenuItem value=""><em>Ninguna</em></MenuItem>
                  {companyData && companyData?.content?.map((company: any, index: any) => (
                    <MenuItem key={index} value={company}>{company.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="center">ID</TableCell> */}
                  <TableCell align="center">Compañía</TableCell>
                  <TableCell align="center">Paquete</TableCell>
                  <TableCell align="center">Mensajero</TableCell>
                  <TableCell align="center">Destinatario</TableCell>
                  <TableCell align="center">Zona</TableCell>
                  <TableCell align="center">Fecha</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData && reportData?.content?.map((row: any) => (
                  <TableRow key={row.id}>
                    {/* <TableCell align="center">{row.id}</TableCell> */}
                    <TableCell align="center">{row?.company?.name}</TableCell>
                    <TableCell align="center">{row?.deliveryCode}</TableCell>
                    <TableCell align="center">{row?.deliveryCourier?.firstName ? row?.deliveryCourier?.firstName +" "+ row?.deliveryCourier?.lastName : "No especificado" }</TableCell>
                    <TableCell align="center">{row?.name}</TableCell>
                    <TableCell align="center">{row?.route?.zone?.name}</TableCell>
                    <TableCell align="center">{row?.dueDate?.substring(0, 10)}</TableCell>
                  </TableRow>
                ))}
                {!reportData ||
                !reportData.content ||
                (reportData.content?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={12} align='center'>
                    {"No hay registros"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FancyTablePagination
            count={
              reportData?.content?.length > 0 ? reportData?.content?.length : 0
            }
            rowsPerPage={reportData.size}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            totalElements={reportData.totalElements}
            totalPages={reportData.totalPages}
          />
        </Grid>
      </Grid>
    </FancyPaper >
  );
};

export default SettingReportOrders;
