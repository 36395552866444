import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { formatDate } from "../../utils/UtilsFunctions";
import { UrlBase } from "../../url/Urls";

export const getRequestSuccessDelivery = async (startDate:any, endDate:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_SUCCESS_DELIVERY(startDate,endDate))    
    .then(async (response:any) => {
      responseData = await response.data;
    })
    .catch((error:any) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestRejectDelivery = async (startDate:any, endDate:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_REJECT_DELIVERY(startDate,endDate))    
    .then(async (response:any) => {
      responseData = await response.data;
    })
    .catch((error:any) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestDeliveryStatics = async (startDate:any, endDate:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_DELIVERY_STATICS(startDate,endDate))    
    .then(async (response:any) => {
      responseData = await response.data;
    })
    .catch((error:any) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestTimelinessPercentage = async (startDate:any, endDate:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_TIMELINESS_PERCENTAGE(startDate,endDate))    
    .then(async (response:any) => {
      responseData = await response.data;
    })
    .catch((error:any) => {
      throw new Error(error.message);     
    });
  return responseData;
};

//REQUEST_PENDING_RATE

export const getRequestPendingRate = async (startDate:any, endDate:any ) => {
  let responseData = {}; 
 
  await privateFetch 
    .get(URL.REQUEST_PENDING_RATE(startDate,endDate))    
    .then(async (response:any) => {
      responseData = await response.data;
    })
    .catch((error:any) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestCoverageExcel = async () => {
  try {
      window.open(UrlBase.base_url + UrlBase.api_context + URL.REQUEST_COVERAGE_EXCEL(), 'blank');
  } catch (error: any) {
      throw new Error("Error al obtener reporte de pedidos");
  }

};




